<template>
  <div>
    <h4>ปักหมุดรูปวอลเปเปอร์</h4>

    <div class="mb-1 mt-1">
      <b-button
        v-if="!editBookmarkWallpaper"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        size="sm"
        :disabled="isLoading"
        @click="startEditBookmark"
      >
        <feather-icon icon="EditIcon" class="mr-50" />
        <span class="align-middle">
          จัดการหมุดวอลเปเปอร์
        </span>
      </b-button>
      <div v-else class="d-flex flex-row" style="gap: 0.5rem;">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="success" size="sm" @click="saveEditBookmark">
          <feather-icon icon="SendIcon" class="mr-50" />
          <span class="align-middle"> บันทึก ({{ localListWallpaper.length }})</span>
        </b-button>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" size="sm" @click="cancelEditBookmark">
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">
            ยกเลิก
          </span>
        </b-button>
      </div>
    </div>

    <b-row>
      <b-col cols="12" :lg="editBookmarkWallpaper ? 8 : 12">
        <b-card>
          <h5 class="mb-0">รายการวอลเปเปอร์ที่ถูกปักหมุด</h5>
          <hr />
          <div v-if="editBookmarkWallpaper">
            <draggable v-model="localListWallpaper" class="list-group list-group-flush cursor-move" tag="ul">
              <!-- <transition-group type="transition" name="flip-list"> -->
              <b-list-group-item v-for="item in localListWallpaper" :key="item.id" tag="li">
                <wallpaper-item-select :item="item" mode="remove" @removeFromBookmark="removeFromBookmark" />
              </b-list-group-item>
              <!-- </transition-group> -->
            </draggable>
            <p v-if="localListWallpaper.length === 0" class="text-center text-muted mt-2 mb-1">
              โปรดเลือกวอลเปเปอร์เพื่อทำการปักหมุด
            </p>
          </div>
          <div v-else>
            <b-overlay :show="isLoading" spinner-small>
              <div v-for="item in listWallpaperBookmark" :key="item.id">
                <wallpaper-item-select :item="item" />
              </div>
              <p v-if="listWallpaperBookmark.length === 0" class="text-center text-muted mt-2 mb-1">
                ยังไม่ได้ปักหมุดวอลเปเปอร์
              </p>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
      <b-col v-show="editBookmarkWallpaper" cols="12" lg="4">
        <b-card>
          <h5>ค้นหาวอลเปเปอร์</h5>
          <hr />
          <list-wallpaper-select @addToBookmark="addToLocalBookmark" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ListWallpaperSelect from './components/ListWallpaperSelect.vue'
import WallpaperItemSelect from './components/WallpaperItemSelect.vue'

export default {
  components: {
    ListWallpaperSelect,
    WallpaperItemSelect,
    draggable,
  },
  data() {
    return {
      listWallpaperBookmark: [],
      localListWallpaper: [],
      editBookmarkWallpaper: false,
      isLoading: false,
    }
  },
  mounted() {
    this.fetchWallpaperListBookmark()
  },
  methods: {
    async fetchWallpaperListBookmark() {
      this.isLoading = true
      const resp = await this.api.getV2('api/admin/admin-topic-images/bookmark/fetch-book-mark-only').catch(() => null)

      if (resp && resp?.code === 200) {
        this.listWallpaperBookmark = [...resp?.data]
      }

      this.isLoading = false
    },
    addToLocalBookmark(item) {
      const isExited = [...this.localListWallpaper].find(v => v.id === item.id)

      if (isExited) {
        this.gDisplayToast('วอลเปเปอร์นี้อยู่ในหมุดแล้ว', '', 'danger')
        return
      }

      this.localListWallpaper.push(item)
    },
    removeFromBookmark(item) {
      this.localListWallpaper = [...this.localListWallpaper].filter(v => v.id !== item.id)
    },
    startEditBookmark() {
      this.localListWallpaper = [...this.listWallpaperBookmark]
      this.editBookmarkWallpaper = true
    },
    cancelEditBookmark() {
      this.localListWallpaper = []
      this.editBookmarkWallpaper = false
      this.fetchWallpaperListBookmark()
    },
    async saveEditBookmark() {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันปักหมุดวอลเปเปอร์')
      if (!isConfirm) return

      this.gOpenPageLoading()
      const request = {
        new_bookmark_order: [...this.localListWallpaper].map(v => v.id),
      }
      const resp = await this.api.postV2('api/admin/admin-topic-images/bookmark', request, this)
      this.gClosePageLoading()
      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }

      if (resp?.code === 200) {
        this.gDisplayToast('ปักหมุดวอลเปเปอร์สำเร็จแล้ว')
        this.localListWallpaper = []
        this.editBookmarkWallpaper = false
        this.fetchWallpaperListBookmark()
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.data?.message, 'danger')
      }
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 0.2s ease-in;
}
</style>
