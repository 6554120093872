<template>
  <div>
    <b-form-input
      v-model="searchTerm"
      placeholder="ระบุชื่อวอลเปเปอร์"
      type="text"
      class="d-inline-block"
      :disabled="loading"
    />
    <b-card no-body class="mb-0 w-100 mt-50">
      <b-overlay
        :show="isFetchShopNameLoading || loading"
        spinner-small
        :opacity="$store.state.appConfig.layout.skin === 'dark' ? 0 : 0.12"
        variant="dark"
      >
        <v-select
          v-model="searchByShops"
          multiple
          :get-option-label="op => `${op.shop_name} (${op.email})`"
          :options="shopNameList"
          placeholder="เลือก ร้านค้า (อีเมล)"
        />
        <template #overlay>
          <div />
        </template>
      </b-overlay>
    </b-card>

    <b-btn type="button" variant="primary" :disabled="loading" block class="mt-50" @click="onClickSearch">
      ค้นหา
    </b-btn>

    <hr />
    <h5>ผลการค้นหา</h5>
    <b-overlay :show="loading" spinner-small style="min-height: 100px;">
      <div v-for="item in wallpaperList" :key="item.id">
        <wallpaper-item-select :item="item" @addToBookmark="addToBookmark" mode="add" />
      </div>
    </b-overlay>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalPage"
      :per-page="perPage"
      first-number
      align="center"
      last-number
      prev-class="prev-item"
      next-class="next-item"
      :disabled="loading"
      @input="onPageChange"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import WallpaperItemSelect from './WallpaperItemSelect.vue'

export default {
  components: {
    vSelect,
    WallpaperItemSelect,
  },
  data() {
    return {
      wallpaperList: [],
      shopNameList: [],
      loading: false,
      currentPage: 1,
      perPage: 1,
      totalPage: 1,
      searchTerm: '',
      searchByShops: [],
      isFetchShopNameLoading: false,
    }
  },
  created() {
    this.fetchShopList()
  },
  methods: {
    async fetchShopList() {
      this.isFetchShopNameLoading = true
      const resp = await this.api.getV2('api/admin/partners/fetch-all-shop-name').catch(() => null)
      this.isFetchShopNameLoading = false
      if (resp?.code === 200) {
        this.shopNameList = [...resp?.data]
      }
    },
    async fetchAllWallpaperList(page = 1, filterByName = '', shopList = []) {
      // const rootEle = document.documentElement
      // rootEle.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })

      this.loading = true

      const request = {}
      if (shopList.length > 0) {
        request.shop_ids = shopList.map(v => v.id)
      }

      const resp = await this.api
        .postV2(
          `api/admin/admin-topic-images/v2/admin-topic-images?page=${page}&filterType=image&filterByName=${filterByName}`,
          request,
          this,
        )
        .catch(() => null)

      if (resp && resp?.code === 200) {
        const { per_page, total, data } = resp.data
        // eslint-disable-next-line camelcase
        this.perPage = per_page
        this.totalPage = total
        this.wallpaperList = [...data]
      }

      this.loading = false
    },
    onPageChange(value) {
      this.fetchAllWallpaperList(value, this.searchTerm, this.searchByShops)
    },
    onClickSearch(e) {
      e.preventDefault()

      if (this.currentPage > 1) {
        this.currentPage = 1
      } else {
        this.fetchAllWallpaperList(1, this.searchTerm, this.searchByShops)
      }
    },
    addToBookmark(item) {
      this.$emit('addToBookmark', item)
    },
  },
}
</script>

<style lang="scss" scoped></style>
