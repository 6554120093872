<template>
  <div class="wallpaper-item-select-container">
    <div class="ribbon-preview bg-primary" :class="[item.user.role_data_preview === 'admin' ? 'bg-warning' : '']">
      <p class="m-0 text-uppercase">{{ gFormatRolePreview(item.user.role_data_preview) }}</p>
    </div>
    <div class="wallpaper-item-select-image-preview" @click="gZoomImageFromUrl(item.image_preview)">
      <b-img-lazy :src="item.image_preview" :alt="item.name" />
    </div>
    <p>
      {{ item.name }}
    </p>
    <p>
      สถานะรีวิว
      <b-badge :variant="csFormat.formatVariantStatusBadge(item.approved_status)">{{
        csFormat.formatStatusBadge(item.approved_status)
      }}</b-badge>
    </p>
    <p>
      รูปแบบการขาย
      <b-badge :variant="item.hide_in_store ? 'danger' : 'success'">
        {{ item.hide_in_store ? 'Redeem' : 'Redeem&Coin' }}
      </b-badge>
    </p>
    <p>ผู้สร้าง (อีเมล) : {{ item.user.email }}</p>
    <p>
      ชื่อร้าน :
      <span v-if="item.user.role_data_preview === 'admin'">
        Ongphra
      </span>
      <span v-else>
        {{ item.user.shop_name_preview }}
      </span>
    </p>
    <b-button
      v-if="mode !== 'preview'"
      :variant="cBtnAddOrRemoveVariant"
      size="sm"
      block
      class="mt-50"
      @click="onClickAddOrRemoveBookmark"
    >
      <feather-icon :icon="cBtnAddOrRemoveIcon" class="mr-50" />
      {{ cBtnAddOrRemoveDisplay }}
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true,
    },
    mode: {
      type: String,
      default: 'preview', // ['preview','add','remove']
    },
  },
  data() {
    return {}
  },
  computed: {
    cBtnAddOrRemoveDisplay() {
      if (this.mode === 'add') return 'เพิ่มในรายการปักหมุด'

      if (this.mode === 'remove') return 'ลบในรายการปักหมุด'

      return ''
    },
    cBtnAddOrRemoveIcon() {
      if (this.mode === 'add') return 'PlusIcon'

      if (this.mode === 'remove') return 'MinusIcon'

      return ''
    },
    cBtnAddOrRemoveVariant() {
      if (this.mode === 'add') return 'success'

      if (this.mode === 'remove') return 'danger'

      return ''
    },
  },
  methods: {
    onClickAddOrRemoveBookmark() {
      if (this.mode === 'add') {
        this.$emit('addToBookmark', this.item)
      }

      if (this.mode === 'remove') {
        this.$emit('removeFromBookmark', this.item)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wallpaper-item-select-container {
  padding: 1rem;
  border: 2px solid #47526f;
  border-radius: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.wallpaper-item-select-image-preview {
  height: 100px;
  background-color: #47526f;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    cursor: zoom-in;
  }
}

.ribbon-preview {
  position: absolute;
  top: 0.2em;
  left: 0;
  padding: 0.2rem 1rem 0.2rem 0.5rem;
  border-radius: 0 0.4em 0.4em 0;
  color: white;
}

.ribbon-preview::before,
.ribbon-preview::after {
  position: absolute;
  content: '';
  display: block;
}

.ribbon-preview::before {
  width: 0.4em;
  height: 100%;
  top: 0;
  left: -0.4em;
  background: inherit;
  border-radius: 0.313em 0 0;
  z-index: 1;
}

.ribbon-preview::after {
  width: 0.4em;
  height: 0.6em;
  bottom: -0.2em;
  left: -0.4em;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0.313em 0 0 0.313em;
}
</style>
